<template>
  <app-layout>
    <template #header> Detalii vanzator </template>
    <page-section>
      <vendors-show-tabs current="vendors.show" :id="id" />
    </page-section>
    <page-section>
      <page-section-header>
        <span class="uppercase">{{ vendor.status?.label }}</span>

        <template v-slot:actions>
          <div class="flex overflow-x-scroll sm:overflow-auto">
            <modal-update-vendor-status
              :vendorId="id"
              @update:vendorStatus="updateVendorStatus"
            />
          </div>
        </template>
      </page-section-header>
      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Informatiile aplicantului
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Detalii personale și aplicație.
          </p>
        </div>
        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Nume</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ vendor.name }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Cod fiscal</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ vendor.fiscal_code }}
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Numar registru</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ vendor.registration_number }}
              </dd>
            </div>

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Documente</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul
                  role="list"
                  class="border border-gray-200 rounded-md divide-y divide-gray-200"
                >
                  <li
                    v-for="attachment in attachments"
                    :key="attachment.id"
                    class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                    :class="{
                      'bg-red-50': notApproved(attachment),
                    }"
                  >
                    <div class="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        class="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span class="ml-2 flex-1 w-0 truncate">
                        {{ attachment.name }}
                      </span>
                      <span class="ml-2 flex-1 w-0 truncate">
                        {{ attachment.notes }}
                      </span>
                    </div>
                    <div class="ml-4 flex-shrink-0">
                      <vendors-show-dialog-attachment
                        :attachment="attachment"
                        :vendorId="id"
                        @update:attachment="attachmentsIndex"
                      />
                      |
                      <a
                        :href="attachment.path"
                        class="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Descarca
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div class="grid grid-cols-1 lg:grid-cols-3 gap-3"></div>
    </page-section>
  </app-layout>
</template>

<script>
import AppLayout from "../layout/AppLayout.vue";
import vendorsApi from "../api/resources/vendorsApi";
import vendorAttachmentsApi from "../api/resources/vendorAttachmentsApi";
import { PaperClipIcon } from "@heroicons/vue/solid";
import VendorsShowDialogAttachment from "../components/VendorsShowDialogAttachment.vue";
import PageSection from "../components/ui/PageSection.vue";
import PageSectionHeader from "../components/ui/PageSectionHeader.vue";
import ModalUpdateVendorStatus from "../components/ModalUpdateVendorStatus.vue";
import VendorsShowTabs from "../components/VendorsShowTabs.vue";

export default {
  components: {
    AppLayout,
    PaperClipIcon,
    VendorsShowDialogAttachment,
    PageSection,
    PageSectionHeader,
    ModalUpdateVendorStatus,
    VendorsShowTabs,
  },

  props: ["id"],

  data() {
    return {
      vendor: {},
      attachments: [],
      loading: false,
    };
  },

  methods: {
    notApproved(attachment) {
      return !attachment.is_approved;
    },

    updateVendorStatus(updatedVendor) {
      this.vendor = updatedVendor;
    },

    attachmentsIndex(page = null) {
      this.loading = true;

      vendorAttachmentsApi
        .index(this.id, page && { page })
        .then((data) => {
          this.attachments = data.data;
          this.meta = data.meta;
          this.links = data.links;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  created() {
    this.loading = true;
    vendorsApi.show(this.$route.params.id).then((data) => {
      this.vendor = data.data;
      this.vendorStatus = this.vendor.status.id;
      this.loading = false;
    });

    this.attachmentsIndex();
  },
};
</script>

<style></style>
