<template>
  <input type="checkbox" v-model="value" />
</template>

<script>
export default {
  props: {
    modelValue: [String, Boolean, Number],
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style></style>
