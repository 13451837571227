import { VENDORS_ATTACHMENTS, VENDORS_ATTACHMENTS_SHOW } from "../endpoints";
import api from "../api";

export default {
    index(vendor, query = null) {
        let url = VENDORS_ATTACHMENTS.replace(":vendor", vendor);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    update(data, { vendor, attachment }) {
        if ((vendor === null) || (attachment === null)) return;

        const url = VENDORS_ATTACHMENTS_SHOW.replace(":vendor", vendor).replace(':attachment', attachment);

        return api.put(url, data);

    }
}