<template>
  <button
    type="button"
    @click="openModal"
    class="font-medium text-indigo-600 hover:text-indigo-500"
  >
    Modifica
  </button>

  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
            >
              <button @click="closeModal" class="absolute top-3 right-3">
                <XIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </button>
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                Modifica atasament
              </DialogTitle>
              <form
                action=""
                method="POST"
                @submit.prevent="onSubmit"
                @keydown="form.errors.clear($event.target.name)"
                class="mt-4"
              >
                <div class="mb-4">
                  <v-label>Notite</v-label>
                  <v-input type="text" v-model="form.notes" class="mt-1" />
                  <v-error :error="form.errors.get('notes')"></v-error>
                </div>
                <div class="mb-4">
                  <v-label>Este aprobat:</v-label>
                  <v-checkbox
                    v-model="form.is_approved"
                    class="ml-2"
                  ></v-checkbox>
                  <v-error :error="form.errors.get('is_approved')"></v-error>
                </div>
                <div class="text-right">
                  <v-button
                    class="
                    text-blue-900 
                    bg-blue-100 
                    border border-transparent rounded-md 
                    hover:bg-blue-200 
                    focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  >
                    <v-button-icon v-if="form.loading">
                      <loader :radius="5" />
                    </v-button-icon>
                    Salveaza
                  </v-button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import Form from "@/core/Form";
import VLabel from "./ui/VLabel.vue";
import VInput from "./ui/VInput.vue";
import VError from "./ui/VError.vue";
import vendorAttachmentsApi from "../api/resources/vendorAttachmentsApi";
import VCheckbox from "./ui/VCheckbox.vue";
import VButton from "./ui/VButton.vue";
import VButtonIcon from "./ui/VButtonIcon.vue";
import Loader from "./Loader.vue";

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    XIcon,
    VLabel,
    VInput,
    VError,
    VCheckbox,
    VButton,
    VButtonIcon,
    Loader,
  },

  props: {
    attachment: {
      type: Object,
    },
    vendorId: {
      type: String,
    },
  },

  emits: ["update:attachment"],

  data() {
    return {
      isOpen: false,
      form: new Form({ notes: "", is_approved: true }),
      loading: false,
    };
  },

  watch: {
    attachment(newValue) {
      this.form.setData(newValue);
    },
  },

  methods: {
    closeModal() {
      this.isOpen = false;
    },

    openModal() {
      this.isOpen = true;
    },

    onSubmit() {
      console.log(this.vendorId);
      this.form
        .submitService(vendorAttachmentsApi, "update", {
          attachment: this.attachment.id,
          vendor: this.vendorId,
        })
        .then((data) => {
          this.form.setData(data);
          this.$emit("update:attachment");
          this.closeModal();
        });
    },
  },
  mounted() {
    this.form.setData(this.attachment);
  },
};
</script>
